
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as testE9QxVsjMb2FKIbRzzNvAT_coju3CQ80AfargDQUoMcwMeta } from "/opt/build/repo/pages/test.vue?macro=true";
import { default as errorB0mL1_LXrJO0X4DJLWXnoVVdwe_z2_6IRaRfT0DLCQYMeta } from "/opt/build/repo/pages/error.vue?macro=true";
import { default as indexaAs6F0uOO1PbNxqH8DD_45e04gSZQCarVD9TCXJYV94KEMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as _91key_93C_WWITvmGNpdhxmnNVoKY8ntBTM9MlH_45IxWRq_mXGU0Meta } from "/opt/build/repo/pages/group/[key].vue?macro=true";
import { default as _91role_93BmTtWhC5NiskepBiUC6zKt7WabzAfyb7yZovg7mUAxAMeta } from "/opt/build/repo/pages/rooms/[key]/[role].vue?macro=true";
export default [
  {
    name: "test",
    path: "/test",
    component: () => import("/opt/build/repo/pages/test.vue")
  },
  {
    name: "error",
    path: "/error",
    component: () => import("/opt/build/repo/pages/error.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "group-key",
    path: "/group/:key()",
    component: () => import("/opt/build/repo/pages/group/[key].vue")
  },
  {
    name: "rooms-key-role",
    path: "/rooms/:key()/:role()",
    component: () => import("/opt/build/repo/pages/rooms/[key]/[role].vue")
  }
]